import router from '~/configs/routes';

import Cart from '~/pages/Cart';

import Home from '~/layouts/Home/Home';

import Docs from '~/pages/document/Docs';

import NotFound from '~/layouts/NotFound';

import Notification from '~/pages/Notification';

import Apis from '~/pages/Apis';
import ApisDetail from '~/pages/Apis/Detail';

import ApiKey from '~/pages/account/ApiKey';
import Profile from '~/pages/account/Profile';
import General from '~/pages/account/General';
import Security from '~/pages/account/Security';
import BonusPoint from '~/pages/account/BonusPoint';
import HistoryLogin from '~/pages/account/HistoryLogin';
import TwoFactor from '~/pages/account/Security/TwoFactor';
import UserNotification from '~/pages/account/Notification';
import ChangePassword from '~/pages/account/Security/ChangePassword';

import Sources from '~/pages/source/Sources';
import SourcesDetail from '~/pages/source/SourceDetail';
import SourceCategory from '~/pages/source/SourceCategory';

import Orders from '~/pages/Billing/Orders';
import Balance from '~/pages/Billing/Balance';
import Recharge from '~/pages/Billing/Recharge';
import Invoices from '~/pages/Billing/Invoices';
import Instances from '~/pages/Billing/Instances';
import BillingTemplate from '~/pages/Billing/Template';
import OrderDetail from '~/pages/Billing/Orders/OrderDetail';
import InvoiceDetail from '~/pages/Billing/Invoices/InvoiceDetail';
import InstanceDetail from '~/pages/Billing/Instances/InstanceDetail';
import TemplateBillingDetail from '~/pages/Billing/Template/TemplateDetail';

import Templates from '~/pages/template/Templates';
import TemplateDetail from '~/pages/template/TemplateDetail';

import RefProfile from '~/components/RefProfile';

import Login from '~/layouts/components/Account/Login';
import Verify from '~/layouts/components/Account/Verify';
import Register from '~/layouts/components/Account/Register';

import ProxyServer from '~/pages/proxy/ProxyServer';

import CloudServer from '~/pages/cloud/CloudServer';

export const privateRoutes = [
    { path: '*', component: NotFound },
    { path: router.home, component: Home },
    { path: router.docs, component: Docs },
    { path: router.cart, component: Cart },
    { path: router.services, component: Apis },
    { path: router.apikey, component: ApiKey },
    { path: router.user, component: RefProfile },
    { path: router.profile, component: Profile },
    { path: router.general, component: General },
    { path: router.sources, component: Sources },
    { path: router.billing, component: Recharge },
    { path: router.security, component: Security },
    { path: router.templates, component: Templates },
    { path: router.twoFactor, component: TwoFactor },
    { path: router.billing_orders, component: Orders },
    { path: router.bonus_point, component: BonusPoint },
    { path: router.billing_balance, component: Balance },
    { path: router.proxy_server, component: ProxyServer },
    { path: router.cloud_server, component: CloudServer },
    { path: router.login, component: Login, layout: null },
    { path: router.billing_invoices, component: Invoices },
    { path: router.notifications, component: Notification },
    { path: router.history_login, component: HistoryLogin },
    { path: router.billing_instances, component: Instances },
    { path: router.verify, component: Verify, layout: null },
    { path: router.notification, component: UserNotification },
    { path: router.changePassword, component: ChangePassword },
    { path: router.services + '/:slug', component: ApisDetail },
    { path: router.register, component: Register, layout: null },
    { path: router.billing_templates, component: BillingTemplate },
    { path: router.sources + '/:category', component: SourceCategory },
    { path: router.sources + '/detail/:slug', component: SourcesDetail },
    { path: router.templates + '/detail/:slug', component: TemplateDetail },
    { path: router.billing_orders + '/:order_id', component: OrderDetail },
    { path: router.billing_invoices + '/:invoice_id', component: InvoiceDetail },
    { path: router.billing_instances + '/:instance_id', component: InstanceDetail },
    { path: router.billing_templates + '/:template_id', component: TemplateBillingDetail },
];
