import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Breadcrumb, Button, Card, Col, Flex, Input, Modal, notification, Row, Select, Space, Tooltip } from 'antd';
import {
    IconCpu,
    IconPlus,
    IconWorld,
    IconMinus,
    IconChecks,
    IconNetwork,
    IconArrowLeft,
    IconShoppingBag,
    IconDeviceFloppy,
    IconDeviceSdCard,
    IconShoppingCartPlus,
} from '@tabler/icons-react';

import router from '~/configs/routes';
import { convertCurrency } from '~/configs';
import { logoutUserSuccess } from '~/redux/reducer/auth';
import { requestUserDeployCloudServer, requestUserGetCloudServer } from '~/services/cloud';

import imageCloudServerOs from '~/assets/image/cloud-server-os.png';
import IconCheckMarkDefault from '~/assets/icon/IconCheckMarkDefault';
import imageCloudServerInfo from '~/assets/image/cloud-server-info.png';
import imageCloudServerRegion from '~/assets/image/cloud-server-region.png';
import imageCloudServerCycles from '~/assets/image/cloud-server-cycles.png';
import imageCloudServerScript from '~/assets/image/cloud-server-script.png';
import imageCloudServerProduct from '~/assets/image/cloud-server-product.png';

const cyclesPayments = [
    { title: '1 Tháng', discount: '' },
    { title: '3 Tháng', discount: '-5%' },
    { title: '6 Tháng', discount: '-10%' },
    { title: '12 Tháng', discount: '-15%' },
];

function CloudServer() {
    const [modalVisible, setModalVisible] = useState(false);
    const [loadingDeploy, setLoadingDeploy] = useState(false);
    const [cloudServerLists, setCloudServerLists] = useState([]);

    const [activeRegionId, setActiveRegionId] = useState(null);
    const [cloudServerRegionSelect, setCloudServerRegionSelect] = useState(null);

    const [activeTemplateId, setActiveTemplateId] = useState(0);
    const [cloudServerTemplates, setCloudServerTemplates] = useState([]);
    const [templateSelectOption, setTemplateSelectOption] = useState(null);
    const [cloudServerTemplateSelect, setCloudServerTemplateSelect] = useState(null);

    const [activeCyclesId, setActiveCyclesId] = useState(0);
    const [cloudServerCyclesSelect, setCloudServerCyclesSelect] = useState(null);

    const [activeProductId, setActiveProductId] = useState(0);
    const [cloudServerProducts, setCloudServerProducts] = useState([]);
    const [cloudServerProductSelect, setCloudServerProductSelect] = useState(null);

    const [activeScriptId, setActiveScriptId] = useState({});
    const [cloudServerScripts, setCloudServerScripts] = useState([]);
    const [cloudServerScriptSelect, setCloudServerScriptSelect] = useState([]);

    const [displayName, setDisplayName] = useState('');
    const [cloudServerCount, setCloudServerCount] = useState(1);
    const [errorDisplayName, setErrorDisplayName] = useState('');

    const totalPrice =
        cloudServerProductSelect?.pricings
            .filter((pricing) => pricing.cycles.display_name === cloudServerCyclesSelect)
            .map((pricing) => pricing.price * (1 - pricing.discount / 100) * cloudServerCount)
            .shift() || 'N/A';

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        document.title = 'Thegioicode.vn - Khởi tạo máy chủ';

        const fetch = async () => {
            const result = await requestUserGetCloudServer();

            if (result.status === 401 || result.status === 403) {
                dispatch(logoutUserSuccess());
                navigate(`${router.login}?redirect_url=${pathname}`);
            } else if (result.status === 200) {
                setCloudServerLists(result.data);

                if (result.data.length > 0) {
                    setActiveRegionId(result.data[0].id);
                    setCloudServerRegionSelect(result.data[0]);

                    setCloudServerTemplates(result.data[0].templates);
                    setTemplateSelectOption(result.data[0].templates[0].versions[0].code);
                    setCloudServerTemplateSelect(result.data[0].templates[0].versions[0]);

                    setCloudServerCyclesSelect(cyclesPayments[0].title);

                    setCloudServerProducts(result.data[0].products);
                    setCloudServerProductSelect(result.data[0].products[0]);

                    setCloudServerScripts(result.data[0].templates[0].versions[0].scripts);

                    setDisplayName(currentUser.username);
                }
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: result.error || 'Lỗi hệ thống vui lòng thử lại sau',
                });
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelectCloudServerRegion = (region) => {
        setActiveRegionId(region.id);
        setCloudServerRegionSelect(region);

        // Set lại hệ điều hành mới
        setActiveTemplateId(0);
        setCloudServerTemplates(region.templates);
        setTemplateSelectOption(region.templates[0].versions[0].code);
        setCloudServerTemplateSelect(region.templates[0].versions[0]);
    };

    const handleSelectCloudServerTemplate = (template, index) => {
        if (activeTemplateId !== index) {
            setActiveTemplateId(index);
            setTemplateSelectOption(template.versions[0].code);
            setCloudServerTemplateSelect(template.versions[0]);

            setCloudServerScriptSelect([]);
            setCloudServerScripts(template.versions[0].scripts);
        }
    };

    const handleSelectOptionTemplate = (value, template) => {
        const versionIndex = template.versions.findIndex((tem) => tem.code === value);

        setTemplateSelectOption(value);
        setCloudServerTemplateSelect(template.versions[versionIndex]);

        setCloudServerScripts(template.versions[versionIndex].scripts);
    };

    const handleSelectScript = (script, index) => {
        setActiveScriptId((prevState) => ({
            ...prevState,
            [index]: !prevState[index], // Bật/tắt trạng thái active cho script đã chọn
        }));

        setCloudServerScriptSelect((prevSelect) => {
            const isSelected = prevSelect.some((selectedScript) => selectedScript.name === script.name); // Kiểm tra xem script đã được chọn chưa
            if (isSelected) {
                // Nếu script đã được chọn, xóa khỏi mảng
                return prevSelect.filter((selectedScript) => selectedScript.name !== script.name);
            } else {
                // Nếu script chưa được chọn, thêm vào mảng
                return [...prevSelect, script];
            }
        });
    };

    const handleChangeDisplayName = (e) => {
        const value = e.target.value;

        // Kiểm tra dữ liệu đầu vào: không có dấu, không khoảng trắng, chỉ cho phép chữ, số và các ký tự đặc biệt !@#$%^&*()_.-
        if (/^[A-Za-z0-9!@#$%^&*()_.-]*$/.test(value)) {
            setErrorDisplayName(''); // Xóa lỗi nếu hợp lệ
        } else {
            setErrorDisplayName('Không nên có dấu, khoảng trống và ký tự đặc biệt chỉ chứa !@#$%^&*()_.-');
        }

        setDisplayName(value);
    };

    const handleCreateCloudServer = async () => {
        if (!cloudServerRegionSelect || !cloudServerRegionSelect.code) {
            return notification.error({
                message: 'Thông báo',
                description: 'Vui lòng chọn khu vực đặt máy chủ',
            });
        }
        if (!cloudServerTemplateSelect || !cloudServerTemplateSelect.code) {
            return notification.error({
                message: 'Thông báo',
                description: 'Vui lòng chọn hệ điều hành máy chủ',
            });
        }
        if (!cloudServerCyclesSelect) {
            return notification.error({
                message: 'Thông báo',
                description: 'Vui lòng chọn chu kỳ thanh toán',
            });
        }

        if (!cloudServerProductSelect || !cloudServerProductSelect.code) {
            return notification.error({
                message: 'Thông báo',
                description: 'Vui lòng chọn cấu hình cần tạo',
            });
        }

        if (totalPrice > currentUser.wallet.total_balance) {
            return notification.error({
                message: 'Thông báo',
                description: 'Số dư ví không đủ thanh toán đơn hàng này',
            });
        }

        const scripts = cloudServerScriptSelect.map((script) => script.code);

        const data = {
            display_name: displayName,
            price_mode: cloudServerCyclesSelect,
            product_code: cloudServerProductSelect.code,
            region_code: cloudServerRegionSelect.code,
            template_code: cloudServerTemplateSelect.code,
            scripts: scripts,
            count: cloudServerCount,
        };

        setLoadingDeploy(true);

        const result = await requestUserDeployCloudServer(data);

        setModalVisible(false);
        setLoadingDeploy(false);
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutUserSuccess());
            navigate(`${router.login}?redirect_url=${pathname}`);
        } else if (result?.status === 200) {
            navigate(`${router.billing_instances}`);

            notification.success({
                message: 'Thông báo',
                description: result.message,
            });
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    return (
        <Row style={{ rowGap: 16 }}>
            <Col span={24}>
                <Flex className="gap-2 pl-2">
                    <Button size="small" className="box-center" onClick={() => navigate(router.home)}>
                        <IconArrowLeft size={18} />
                    </Button>
                    <Breadcrumb
                        items={[
                            {
                                title: <Link to={router.home}>Trang chủ</Link>,
                            },
                            {
                                title: 'Tạo máy chủ',
                            },
                        ]}
                    />
                </Flex>
            </Col>

            <Col md={19} xs={24} style={{ padding: '0 8px' }}>
                <Row style={{ marginLeft: -8, marginRight: -8, rowGap: 16 }}>
                    <Col span={24} style={{ paddingLeft: 8, paddingRight: 8 }}>
                        <Card
                            title={
                                <h2 className="font-size-18 font-semibold mb-0 white-space-break">
                                    <div className="d-flex align-items-center gap-2">
                                        <Avatar
                                            className="rounded-0"
                                            src={imageCloudServerRegion}
                                            style={{ width: 25, height: 25, lineHeight: 25, fontSize: 18 }}
                                        />
                                        <div className="flex-1">Khu vực</div>
                                    </div>
                                </h2>
                            }
                        >
                            <Row style={{ marginLeft: -8, marginRight: -8, rowGap: 16 }}>
                                {cloudServerLists.map((region) => (
                                    <Col md={6} xs={24} style={{ paddingLeft: 8, paddingRight: 8 }} key={region.id}>
                                        <div
                                            className="item-plan-instance align-items-center"
                                            data-active={activeRegionId === region.id}
                                            onClick={() => handleSelectCloudServerRegion(region)}
                                        >
                                            <Flex align="center" justify="center" className="w-full gap-3">
                                                <Avatar
                                                    className="rounded-0"
                                                    src={region.icon_url}
                                                    style={{ width: 35, height: 35, lineHeight: 35, fontSize: 18 }}
                                                />

                                                <div className="title w-auto font-bold">{region.full_name}</div>
                                            </Flex>

                                            {activeRegionId === region.id && <IconCheckMarkDefault />}
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Card>
                    </Col>

                    <Col span={24} style={{ paddingLeft: 8, paddingRight: 8 }}>
                        <Card
                            title={
                                <h2 className="font-size-18 font-semibold mb-0 white-space-break">
                                    <div className="d-flex align-items-center gap-2">
                                        <Avatar
                                            className="rounded-0"
                                            src={imageCloudServerOs}
                                            style={{ width: 25, height: 25, lineHeight: 25, fontSize: 18 }}
                                        />
                                        <div className="flex-1">Hệ điều hành</div>
                                    </div>
                                </h2>
                            }
                        >
                            <Row style={{ marginLeft: -8, marginRight: -8, rowGap: 16 }}>
                                {cloudServerTemplates.map((template, index) => (
                                    <Col md={6} xs={24} style={{ paddingLeft: 8, paddingRight: 8 }} key={index}>
                                        <div
                                            className="card_item_image_shadow flex-column p-0"
                                            data-active={activeTemplateId === index}
                                            onClick={() => handleSelectCloudServerTemplate(template, index)}
                                        >
                                            <Flex align="center" justify="center" className="w-full gap-3 py-3 border-bottom">
                                                <Avatar
                                                    className="rounded-0"
                                                    src={template.icon_url}
                                                    style={{ width: 35, height: 35, lineHeight: 35, fontSize: 18 }}
                                                />

                                                <div className="title width-max-content font-bold">{template.group}</div>
                                            </Flex>

                                            <Select
                                                className="w-full text-subtitle text-center input_select_link"
                                                placeholder="Chọn phiên bản"
                                                dropdownStyle={{ textAlign: 'center' }}
                                                value={activeTemplateId === index ? templateSelectOption : undefined}
                                                onChange={(e) => handleSelectOptionTemplate(e, template)}
                                                options={template.versions.map((version) => {
                                                    return {
                                                        label: version.name,
                                                        value: version.code,
                                                    };
                                                })}
                                            />

                                            {activeTemplateId === index && <IconCheckMarkDefault />}
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Card>
                    </Col>

                    <Col span={24} style={{ paddingLeft: 8, paddingRight: 8 }}>
                        <Card
                            title={
                                <h2 className="font-size-18 font-semibold mb-0 white-space-break">
                                    <div className="d-flex align-items-center gap-2">
                                        <Avatar
                                            className="rounded-0"
                                            src={imageCloudServerCycles}
                                            style={{ width: 25, height: 25, lineHeight: 25, fontSize: 18 }}
                                        />
                                        <div className="flex-1">Chu kỳ thanh toán</div>
                                    </div>
                                </h2>
                            }
                        >
                            <div className="border rounded">
                                <div className="p-2 border-bottom text-center font-bold" style={{ backgroundColor: '#f0f2f4' }}>
                                    Thời gian thanh toán
                                </div>
                                <Row>
                                    {cyclesPayments.map((cycles, index) => (
                                        <Col md={6} xs={24} key={index}>
                                            <div
                                                onClick={() => {
                                                    setActiveCyclesId(index);
                                                    setCloudServerCyclesSelect(cycles.title);
                                                }}
                                                className={
                                                    activeCyclesId === index
                                                        ? 'border-2 cursor-pointer p-2 text-center font-weight-bold border-primary text-primary'
                                                        : 'border border-2 cursor-pointer p-2 text-center font-weight-bold hover-border-primary hover-blue'
                                                }
                                            >
                                                {cycles.title}
                                                {cycles.discount && (
                                                    <span className="bg-card-gray font-size-11 px-1 rounded-md ml-1 text-danger font-bold">
                                                        {cycles.discount}
                                                    </span>
                                                )}

                                                {activeCyclesId === index && <IconCheckMarkDefault />}
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </Card>
                    </Col>

                    <Col span={24} style={{ paddingLeft: 8, paddingRight: 8 }}>
                        <Card
                            title={
                                <h2 className="font-size-18 font-semibold mb-0 white-space-break">
                                    <div className="d-flex align-items-center gap-2">
                                        <Avatar
                                            className="rounded-0"
                                            src={imageCloudServerProduct}
                                            style={{ width: 25, height: 25, lineHeight: 25, fontSize: 18 }}
                                        />
                                        <div className="flex-1">Gói dịch vụ</div>
                                    </div>
                                </h2>
                            }
                        >
                            <Row style={{ marginLeft: -8, marginRight: -8, rowGap: 16 }}>
                                {cloudServerProducts.map((product, index) => (
                                    <Col md={6} xs={24} style={{ paddingLeft: 8, paddingRight: 8 }} key={index}>
                                        <div
                                            className="item-plan-instance flex-column align-items-center mr-0 mt-0"
                                            data-active={activeProductId === index}
                                            onClick={() => {
                                                setActiveProductId(index);
                                                setCloudServerProductSelect(product);
                                            }}
                                        >
                                            <div className="title font-size-16 font-bold text-center mb-2">{product.title}</div>
                                            <div className="font-size-23 font-bold text-center text-primary">
                                                {product.pricings
                                                    .filter((pricing) => pricing.cycles.display_name === cloudServerCyclesSelect)
                                                    .map((pricing) => convertCurrency(pricing.price * (1 - pricing.discount / 100)))
                                                    .shift() || 'N/A'}
                                            </div>
                                            <div className="font-size-18 text-center text-line-through text-subtitle">
                                                {product.pricings
                                                    .filter((pricing) => pricing.cycles.display_name === cloudServerCyclesSelect)
                                                    .map((pricing) => convertCurrency(pricing.price))
                                                    .shift() || 'N/A'}
                                            </div>

                                            <div className="border-top w-full mt-4">
                                                <Flex
                                                    align="center"
                                                    justify="space-between"
                                                    className="border-bottom py-2 px-1 font-bold text-subtitle"
                                                >
                                                    <div className="box-center">
                                                        <IconCpu size={20} />
                                                        <span className="ml-1">CPU</span>
                                                    </div>
                                                    <div>{product.core} vCPU</div>
                                                </Flex>
                                                <Flex
                                                    align="center"
                                                    justify="space-between"
                                                    className="border-bottom py-2 px-1 font-bold text-subtitle"
                                                >
                                                    <div className="box-center">
                                                        <IconDeviceSdCard size={20} />
                                                        <span className="ml-1">RAM</span>
                                                    </div>
                                                    <div>{product.memory / 1024} GB</div>
                                                </Flex>
                                                <Flex
                                                    align="center"
                                                    justify="space-between"
                                                    className="border-bottom py-2 px-1 font-bold text-subtitle"
                                                >
                                                    <div className="box-center">
                                                        <IconDeviceFloppy size={20} />
                                                        <span className="ml-1">SSD</span>
                                                    </div>
                                                    <div>{product.disk} GB</div>
                                                </Flex>
                                                <Flex
                                                    align="center"
                                                    justify="space-between"
                                                    className="border-bottom py-2 px-1 font-bold text-subtitle"
                                                >
                                                    <div className="box-center">
                                                        <IconNetwork size={20} />
                                                        <span className="ml-1">Bandwidth</span>
                                                    </div>
                                                    <div>Unlimited</div>
                                                </Flex>
                                                <Flex
                                                    align="center"
                                                    justify="space-between"
                                                    className="border-bottom py-2 px-1 font-bold text-subtitle"
                                                >
                                                    <div className="box-center">
                                                        <IconWorld size={20} />
                                                        <span className="ml-1">IPv4</span>
                                                    </div>
                                                    <div>1 Địa chỉ</div>
                                                </Flex>
                                            </div>

                                            {activeProductId === index && <IconCheckMarkDefault />}
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Card>
                    </Col>

                    {cloudServerScripts.length > 0 && (
                        <Col span={24} style={{ paddingLeft: 8, paddingRight: 8 }}>
                            <Card
                                title={
                                    <h2 className="font-size-18 font-semibold mb-0 white-space-break">
                                        <div className="d-flex align-items-center gap-2">
                                            <Avatar
                                                className="rounded-0"
                                                src={imageCloudServerScript}
                                                style={{ width: 25, height: 25, lineHeight: 25, fontSize: 18 }}
                                            />
                                            <div className="flex-1">Tiện tích bổ sung</div>
                                        </div>
                                    </h2>
                                }
                            >
                                <Row style={{ marginLeft: -8, marginRight: -8, rowGap: 16 }}>
                                    {cloudServerScripts.map((script, index) => (
                                        <Col md={6} xs={24} style={{ paddingLeft: 8, paddingRight: 8 }} key={index}>
                                            <div
                                                className="item-plan-instance align-items-center"
                                                data-active={activeScriptId[index] || false}
                                                onClick={() => handleSelectScript(script, index)}
                                            >
                                                <Flex align="center" justify="center" className="w-full gap-3">
                                                    <Avatar
                                                        className="rounded-0"
                                                        src={script.icon_url}
                                                        style={{ width: 35, height: 35, lineHeight: 35, fontSize: 18 }}
                                                    />

                                                    <div className="title w-auto font-semibold">{script.name}</div>
                                                </Flex>

                                                {activeScriptId[index] && <IconCheckMarkDefault />}
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Card>
                        </Col>
                    )}

                    <Col span={24} style={{ paddingLeft: 8, paddingRight: 8 }}>
                        <Card
                            title={
                                <h2 className="font-size-18 font-semibold mb-0 white-space-break">
                                    <div className="d-flex align-items-center gap-2">
                                        <Avatar
                                            className="rounded-0"
                                            src={imageCloudServerInfo}
                                            style={{ width: 25, height: 25, lineHeight: 25, fontSize: 18 }}
                                        />
                                        <div className="flex-1">Thông tin máy chủ</div>
                                    </div>
                                </h2>
                            }
                        >
                            <Row style={{ marginLeft: -8, marginRight: -8, rowGap: 16 }}>
                                <Col md={8} xs={24} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                    <div className="font-size-16 font-semibold mb-3">Số lượng máy chủ</div>
                                    <Space.Compact style={{ width: '100%' }}>
                                        <Button
                                            size="large"
                                            className="box-center"
                                            disabled={cloudServerCount < 2}
                                            onClick={() => setCloudServerCount(cloudServerCount - 1)}
                                        >
                                            <IconMinus size={24} />
                                        </Button>
                                        <Input size="large" value={cloudServerCount} classNames={{ input: 'text-center' }} />
                                        <Button
                                            size="large"
                                            className="box-center"
                                            disabled={cloudServerCount > 0}
                                            onClick={() => setCloudServerCount(cloudServerCount + 1)}
                                        >
                                            <IconPlus size={24} />
                                        </Button>
                                    </Space.Compact>
                                </Col>
                                <Col md={16} xs={24} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                    <div className="font-size-16 font-semibold mb-3">Tên máy chủ</div>

                                    <div className="mb-3">
                                        <Input size="large" value={displayName} readOnly onChange={(e) => handleChangeDisplayName(e)} />
                                        {errorDisplayName && <span className="text-danger">{errorDisplayName}</span>}
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Col>

            <Col md={5} xs={24} style={{ padding: '0 8px' }}>
                <div className="cloud_server-right">
                    <Card
                        style={{ height: '100%' }}
                        styles={{ body: { padding: 0, height: 'calc(100% - 56px)' } }}
                        title={
                            <div className="box-center">
                                <IconShoppingBag size={22} />
                                <h2 className="font-size-17 mb-0 font-bold ml-2">Thông tin hóa đơn</h2>
                            </div>
                        }
                    >
                        <div className="cloud_server-container border-top">
                            <div className="cloud_server-content pt-1 pb-0 px-3 border-bottom">
                                <div className="d-flex border-bottom py-2">
                                    <p className="text-primary font-bold mb-0">Khu vực:</p>
                                    <p className="ml-2 mb-0 font-bold word-break-all">{cloudServerRegionSelect?.full_name}</p>
                                </div>
                                <div className="d-flex border-bottom py-2">
                                    <p className="text-primary font-bold mb-0">Hệ điều hành:</p>
                                    <p className="ml-2 mb-0 font-bold word-break-all">{cloudServerTemplateSelect?.name}</p>
                                </div>
                                <div className="border-bottom py-2">
                                    <p className="text-primary font-bold mb-0">Gói dịch vụ:</p>
                                    <p className="mb-0 font-bold">{cloudServerProductSelect?.core} vCPU</p>
                                    <p className="mb-0 font-bold">{cloudServerProductSelect?.memory / 1024} GB RAM</p>
                                    <p className="mb-0 font-bold">{cloudServerProductSelect?.disk} GB SSD</p>
                                </div>
                                <div className="d-flex border-bottom py-2">
                                    <p className="text-primary font-bold mb-0">Chu kỳ:</p>
                                    <p className="ml-2 mb-0 font-bold word-break-all">{cloudServerCyclesSelect}</p>
                                </div>
                                {cloudServerScriptSelect.length > 0 && (
                                    <div className="border-bottom py-2">
                                        <p className="text-primary font-bold mb-0">Tiện tích bổ sung:</p>
                                        {cloudServerScriptSelect.map((script) => (
                                            <div className="mb-0 font-bold">{script.name}</div>
                                        ))}
                                    </div>
                                )}
                                <div className="d-flex border-bottom py-2">
                                    <p className="text-primary font-bold mb-0">Số lượng máy chủ:</p>
                                    <p className="ml-2 mb-0 font-bold word-break-all">{cloudServerCount}</p>
                                </div>
                            </div>

                            <div className="border-top">
                                <div className="cloud_server-footer">
                                    <Flex align="center" justify="space-between" className="px-3 py-2 border-bottom">
                                        <div className="font-bold">Tổng tiền</div>
                                        <span className="font-size-18 font-bold text-primary">{convertCurrency(totalPrice)}</span>
                                    </Flex>

                                    <div className="px-3 py-2 d-flex gap-2">
                                        <Tooltip title="Thêm vào giỏ hàng">
                                            <Button
                                                size="large"
                                                className="box-center border-warning text-warning"
                                                icon={<IconShoppingCartPlus size={24} />}
                                                disabled={loadingDeploy}
                                                onClick={() =>
                                                    notification.error({
                                                        message: 'Thông báo',
                                                        description: 'Chức năng đang được phát triển',
                                                    })
                                                }
                                            />
                                        </Tooltip>

                                        <Button
                                            size="large"
                                            type="primary"
                                            className="box-center flex-1"
                                            icon={<IconChecks size={24} />}
                                            onClick={() => setModalVisible(true)}
                                            loading={loadingDeploy}
                                        >
                                            {loadingDeploy ? <span className="ml-1">Đang khởi tạo...</span> : <span>Tiếp tục</span>}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </Col>

            {modalVisible && (
                <Modal
                    centered
                    closable={false}
                    maskClosable={false}
                    open={modalVisible}
                    onOk={handleCreateCloudServer}
                    onCancel={() => setModalVisible(false)}
                    width={460}
                    okText="Xác nhận"
                    cancelText="Hủy"
                    title="Xác nhận khởi tạo máy chủ"
                >
                    <p>Bạn chắc chắn muốn khởi tạo máy chủ? Hành động sẽ không thể hoàn tác!</p>
                </Modal>
            )}
        </Row>
    );
}

export default CloudServer;
