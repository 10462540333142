import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { HeartFilled } from '@ant-design/icons';
import { Alert, Button, notification } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import config from '~/configs';
import Account from '../Account';
import { startLoadingGlobal, stopLoadingGlobal } from '~/redux/reducer/loading';
import { requestUserCheckVerifyEmail, requestUserSendToVerifyEmail } from '~/services/auth';

const { login } = config.router;

function Verify() {
    const searchParams = new URL(window.location.href).searchParams;

    const email = searchParams.get('email');
    const token = searchParams.get('token');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (email) {
            const fetch = async () => {
                const result = await requestUserCheckVerifyEmail(email, token);
                if (result.status === 200) {
                    if (result.data) {
                        navigate(login);
                        notification.success({
                            message: 'Thông báo',
                            description: result.message,
                        });
                    }
                } else if (result.status === 404) {
                    navigate(login);
                    notification.error({
                        message: 'Thông báo',
                        description: result.error,
                    });
                } else {
                    notification.error({
                        message: 'Thông báo',
                        description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
                    });
                }
            };
            fetch();
        } else {
            navigate(login);
            notification.error({
                message: 'Thông báo',
                description: 'Vui lòng xác minh email',
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    const handleSendToEmail = async () => {
        if (!email) {
            notification.error({
                message: 'Thông báo',
                description: 'Email không tồn tại hoặc đã bị xóa',
            });
        }
        dispatch(startLoadingGlobal());
        const result = await requestUserSendToVerifyEmail(email);

        dispatch(stopLoadingGlobal());
        if (result.status === 1) {
            navigate(login);
            notification.success({
                message: 'Thông báo',
                description: result.message,
            });
        } else if (result.status === 200) {
            notification.success({
                message: 'Thông báo',
                description: result.message,
            });
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    return (
        <Account>
            <HeartFilled className="font-size-50" style={{ color: 'red' }} />
            <h4 className="mt-2 mb-4 font-bold font-size-20 ">Còn 1 bước nữa thôi</h4>

            <span className="text-subtitle" style={{ textAlign: 'justify' }}>
                Chúng tôi đã gửi cho bạn 1 đường dẫn kích hoạt tài khoản tới email <b>{email}</b> của bạn, vui lòng kiểm tra email và mở nó.
                Nếu không tìm thấy tại mục thư đến vui lòng kiểm tra mục thư rác. Bạn chưa nhận được mã?
            </span>
            <span className="cursor-pointer text-link" onClick={handleSendToEmail}>
                {' '}
                Gửi lại mã
            </span>

            <Alert
                className="mt-3"
                message={
                    <div>
                        <b>Lưu ý: </b>
                        <span>
                            Đường dẫn kích hoạt tài khoản chỉ có thời hạn trong vòng <b>30</b> phút
                        </span>
                    </div>
                }
                type="warning"
                showIcon
            />

            <div className="mt-5">
                <Link to={login}>
                    <Button size="large" block className="text-uppercase font-weight-bold">
                        Về trang đăng nhập
                    </Button>
                </Link>
            </div>
        </Account>
    );
}

export default Verify;
