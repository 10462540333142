import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Avatar, Button, Card, Col, Flex, Row } from 'antd';

import Support from './Support';
import router from '~/configs/routes';
import ServiceUsing from './ServiceUsing';
import { convertCurrency } from '~/configs';
import iconSource from '~/assets/image/icon_source.png';
import iconCreateWebsite from '~/assets/image/icon_website.svg';
import imageAvatarDefault from '~/assets/image/avatar-default.png';
import imageCloudServer from '~/assets/image/icon-cloud_server.svg';

const servicesData = [
    {
        id: 1,
        title: 'Dịch vụ tạo website',
        description: 'Bảo mật, nhanh gọn, bảo hành trọn đời',
        link: router.templates,
        src: iconCreateWebsite,
    },
    {
        id: 2,
        title: 'Dịch vụ mã nguồn',
        description: 'Mã nguồn sẵn có dễ dàng cài đặt',
        link: router.sources,
        src: iconSource,
    },
    {
        id: 3,
        title: 'Dịch vụ máy chủ',
        description: 'Khởi tạo máy chủ chỉ vài giây',
        link: router.cloud_server,
        src: imageCloudServer,
    },
];

function Home() {
    const { currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        document.title = 'Thegioicode.vn - Home';
    }, []);

    return (
        <Row style={{ rowGap: 16 }}>
            <Col md={18} style={{ padding: '0 8px' }}>
                <Card className="rounded-15 mb-4" style={{ minHeight: 'calc(-120px + 100vh)' }}>
                    <ServiceUsing />

                    <div>
                        <h2 className="font-semibold font-size-20 mb-5">Sử dụng dịch vụ với hệ sinh thái của chúng tôi</h2>

                        <Row
                            style={{
                                marginLeft: -5,
                                marginRight: -5,
                                rowGap: 10,
                            }}
                        >
                            {servicesData.map((service) => (
                                <Col md={8} xs={24} style={{ paddingLeft: 5, paddingRight: 5 }} key={service.id}>
                                    <Link to={service.link}>
                                        <Card hoverable style={{ padding: 2 }}>
                                            <Flex className="gap-2">
                                                <Avatar
                                                    style={{
                                                        width: 50,
                                                        height: 50,
                                                        lineHeight: 50,
                                                        fontSize: 25,
                                                        background: '#096eff',
                                                    }}
                                                    src={service.src}
                                                />
                                                <div className="link-color flex-1">
                                                    <h4 className="font-bold text-primary line-height-20 mb-0 font-size-16">
                                                        {service.title}
                                                    </h4>
                                                    <p className="line-height-20 mt-2px text-subtitle">{service.description}</p>
                                                </div>
                                            </Flex>
                                        </Card>
                                    </Link>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Card>
            </Col>

            <Col md={6} style={{ padding: '0 8px' }} className="flex-1">
                <Card
                    className="rounded-15 mb-4"
                    title={
                        <h2 className="font-semibold mb-0 white-space-break">
                            <span className="font-size-18 font-semibold">Thông tin tài khoản</span>
                        </h2>
                    }
                >
                    <Flex align="center" justify="center" className="h-full flex-column">
                        <Avatar
                            src={currentUser?.avatar_url || imageAvatarDefault}
                            style={{ fontSize: 50, width: 100, height: 100, lineHeight: 100 }}
                        />
                        <h3 className="text-center font-semibold mt-2 mb-0 font-size-20">{currentUser?.full_name}</h3>
                        <h3 className="text-center mt-1 mb-0 text-subtitle font-size-16">{currentUser?.email}</h3>

                        <div className="w-full mt-6">
                            <Link to={router.profile}>
                                <Button block className="rounded-10 mt-4 min-height-35">
                                    Quản lý tài khoản
                                </Button>
                            </Link>
                            <Link to={router.billing}>
                                <Button block type="primary" className="rounded-10 mt-2 min-height-35 boxshadow-none">
                                    Nạp tiền vào tài khoản
                                </Button>
                            </Link>
                        </div>

                        <Flex justify="space-between" className="mt-8 mb-2 w-full">
                            <span className="text-sub-title font-size-15">Tài khoản chính:</span>
                            <span className="text-primary font-size-18 font-semibold">
                                {convertCurrency(currentUser?.wallet.credit_balance)}
                            </span>
                        </Flex>
                        <Flex justify="space-between" className="w-full">
                            <span className="text-sub-title font-size-15">Tài khoản khuyễn mãi:</span>
                            <span className="text-primary font-size-18 font-semibold">
                                {convertCurrency(currentUser?.wallet.bonus_balance)}
                            </span>
                        </Flex>
                    </Flex>
                </Card>

                <Support />
            </Col>
        </Row>
    );
}

export default Home;
