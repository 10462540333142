import request from '~/utils';

export const requestUserGetCloudServer = async () => {
    try {
        const res = await request.get('/my/cloud/server');

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestUserDeployCloudServer = async (data) => {
    try {
        const res = await request.post('/my/cloud/server/deploy', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
