import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { IconPower, IconRefresh, IconResize, IconServer2, IconSettings } from '@tabler/icons-react';
import { Avatar, Button, Card, Empty, Flex, Modal, Pagination, Space, Spin, Switch, Table, Tooltip, message, notification } from 'antd';

import Billing from '../Billing';
import router from '~/configs/routes';
import { calculateDaysLeft } from '~/configs';
import IconBalance from '~/assets/icon/IconBalance';
import { requestUserAddProductToCart } from '~/services/cart';
import imageAvatarDefault from '~/assets/image/avatar-default.png';
import { loginUserSuccess, logoutUserSuccess } from '~/redux/reducer/auth';
import { requestUserActionInstance, requestUserGetOrderInstances } from '~/services/billing';

function Instances() {
    const [pages, setPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [instances, setInstances] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [page, setPage] = useState(searchParams.get('page') || 1);

    const [action, setAction] = useState(null);
    const [instanceId, setInstanceId] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [loadingAction, setLoadingAction] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        document.title = 'Thegioicode.vn - Danh sách máy chủ đã tạo';

        const fetch = async () => {
            setLoading(true);
            const result = await requestUserGetOrderInstances(page);

            setLoading(false);
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutUserSuccess());
                navigate(`${router.login}?redirect_url=${pathname}`);
            } else if (result?.status === 200) {
                setPages(result.pages);
                setInstances(result.data);
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
                });
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    // Auto Renew
    const handleAutoReNewInstance = async (id) => {
        if (!id) {
            return notification.error({
                message: 'Thông báo',
                description: 'Lỗi lấy ID máy chủ để gia hạn',
            });
        }

        const result = await requestUserActionInstance('auto-renew', id);

        if (result.status === 401 || result.status === 403) {
            dispatch(logoutUserSuccess());
            navigate(`${router.login}?redirect_url=${pathname}`);
        } else if (result?.status === 200) {
            const cloneInstances = [...instances];

            const indexInstance = cloneInstances.findIndex((item) => item.id === id);
            if (indexInstance === -1) {
                return notification.error({
                    message: 'Thông báo',
                    description: 'Không tìm thấy máy chủ trong danh sách',
                });
            }

            cloneInstances[indexInstance].auto_renew = result.data;
            setInstances(cloneInstances);

            notification.success({
                message: 'Thông báo',
                description: result.message,
            });
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    // Stop
    const handleStopInstance = async () => {
        if (!instanceId) {
            return notification.error({
                message: 'Thông báo',
                description: 'Vui lòng chọn máy chủ muốn thao tác',
            });
        }
        if (!action) {
            return notification.error({
                message: 'Thông báo',
                description: 'Vui lòng chọn thao tác với máy chủ',
            });
        }

        setLoadingAction(true);

        const result = await requestUserActionInstance(action, instanceId);

        setLoadingAction(false);
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutUserSuccess());
            navigate(`${router.login}?redirect_url=${pathname}`);
        } else if (result?.status === 200) {
            const cloneInstances = [...instances];

            const indexInstance = cloneInstances.findIndex((item) => item.id === instanceId);
            if (indexInstance === -1) {
                return notification.error({
                    message: 'Thông báo',
                    description: 'Không tìm thấy máy chủ trong danh sách',
                });
            }

            if (action === 'start') {
                cloneInstances[indexInstance].status = 'activated';
            }
            if (action === 'stop') {
                cloneInstances[indexInstance].status = 'stopped';
            }
            setInstances(cloneInstances);
            setAction(null);
            setInstanceId(null);
            setModalVisible(false);

            notification.success({
                message: 'Thông báo',
                description: result.message,
            });
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    // Renew
    const handleRenewOrderInstance = async (id) => {
        if (!id) {
            return notification.error({
                message: 'Thông báo',
                description: 'Vui lòng chọn máy chủ để gia hạn',
            });
        }

        const result = await requestUserAddProductToCart('order-instance', id);

        if (result.status === 401 || result.status === 403) {
            dispatch(logoutUserSuccess());
            navigate(`${router.login}?redirect_url=${pathname}`);
        } else if (result?.status === 200) {
            const { cart_count, ...others } = currentUser;
            dispatch(loginUserSuccess({ cart_count: cart_count + 1, ...others }));

            navigate(router.cart);
            message.success(result.message);
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (index) => <b>{index}</b>,
        },
        {
            title: 'Tên máy chủ',
            key: 'name',
            render: (data) => (
                <Flex align="center">
                    <div className="mr-3">
                        <Avatar
                            src={data.image.icon_url || imageAvatarDefault}
                            style={{ width: 30, height: 30, lineHeight: 30, fontSize: 18 }}
                            alt="Image"
                        />
                    </div>
                    <div>
                        <Link to={`${router.billing_instances}/${data.id}`} className="hover-underline">
                            {data.display_name.toUpperCase()}
                        </Link>
                        <div className="font-size-13">
                            {data.product.core}-{data.product.memory / 1024}-{data.product.disk}
                        </div>
                        <div className="font-size-13">
                            {data.image.name.toLowerCase()} - {data.order_info.access_ipv4}
                        </div>
                    </div>
                </Flex>
            ),
        },
        {
            title: 'Vị trí',
            dataIndex: 'region',
            key: 'region',
            render: (region) => (
                <Fragment>
                    <div>{region.full_name}</div>
                    <div>{region.location}</div>
                </Fragment>
            ),
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                let className = '';
                let style = {};
                let title = status;

                if (status === 'activated') {
                    title = 'Đang hoạt động';
                    className = 'label-light-success font-weight-bold';
                    style = { backgroundColor: '#4caf501a', color: '#4caf50', border: '1px solid #4caf501a' };
                }
                if (status === 'pending' || status === 'rebooting') {
                    if (status === 'pending') {
                        title = 'Đang khởi tạo';
                    }
                    if (status === 'rebooting') {
                        title = 'Đang khởi động';
                    }
                    className = 'label-light-warning font-weight-bold';
                    style = { backgroundColor: '#ff98001a', color: '#ff9800', border: '1px solid #ff98001a' };
                }
                if (status === 'inactivated' || status === 'expired' || status === 'blocked' || status === 'deleted') {
                    if (status === 'stopped') {
                        title = 'Đã dừng lại';
                    }
                    if (status === 'inactivated') {
                        title = 'Vô hiệu hoá';
                    }
                    if (status === 'expired') {
                        title = 'Đã hết hạn';
                    }
                    if (status === 'blocked') {
                        title = 'Đã bị khoá';
                    }
                    if (status === 'deleted') {
                        title = 'Đã bị xoá';
                    }
                    className = 'label-light-danger font-weight-bold';
                    style = { backgroundColor: '#f443361a', color: '#f44336', border: '1px solid #f443361a' };
                }

                return (
                    <div className={className} style={style}>
                        {title}
                    </div>
                );
            },
        },
        {
            title: 'Tự động gia hạn',
            key: 'auto_renew',
            render: (data) => (
                <Switch
                    checkedChildren="Bật"
                    unCheckedChildren="Tắt"
                    value={data.auto_renew}
                    onChange={() => handleAutoReNewInstance(data.id)}
                />
            ),
        },
        {
            title: 'Ngày tạo/Hết hạn',
            key: 'date',
            render: (data) => (
                <Fragment>
                    <span>{moment(data.created_at).format('DD/MM/YYYY HH:mm:ss')}</span>
                    <br />
                    <span>{moment(data.expired_at).format('DD/MM/YYYY HH:mm:ss')}</span>
                    <br />
                    <Fragment>
                        (Còn{' '}
                        <b className={calculateDaysLeft(data.expired_at) < 8 ? 'text-danger' : ''}>{calculateDaysLeft(data.expired_at)}</b>{' '}
                        ngày)
                    </Fragment>
                </Fragment>
            ),
        },
        {
            title: 'Thao tác',
            key: 'action',
            render: (data) => (
                <Space>
                    <Tooltip title={data.status !== 'stopped' ? 'Tắt' : 'Bật'}>
                        <Button
                            className="box-center"
                            size="small"
                            onClick={() => {
                                setAction(data.status !== 'stopped' ? 'stop' : 'start');
                                setInstanceId(data.id);
                                setModalVisible(true);
                            }}
                        >
                            <IconPower width={18} height={18} />
                        </Button>
                    </Tooltip>
                    {data.status !== 'stopped' && (
                        <Tooltip title="Khởi động lại">
                            <Button
                                className="box-center"
                                size="small"
                                onClick={() => {
                                    setAction('reset');
                                    setInstanceId(data.id);
                                    setModalVisible(true);
                                }}
                            >
                                <IconRefresh width={18} height={18} />
                            </Button>
                        </Tooltip>
                    )}
                    <Tooltip title="Gia hạn">
                        <Button
                            className="box-center"
                            size="small"
                            onClick={() => {
                                handleRenewOrderInstance(data.id);
                            }}
                        >
                            <IconBalance width={18} height={18} />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Thay đổi cấu hình">
                        <Button className="box-center" size="small">
                            <IconResize width={18} height={18} />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Cài lại Image">
                        <Button className="box-center" size="small">
                            <IconSettings width={18} height={18} />
                        </Button>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return (
        <Billing
            keyTab="6"
            label={
                <span className="box-align-center gap-2 text-subtitle">
                    <IconServer2 size={20} />
                    Instances
                </span>
            }
        >
            {loading ? (
                <Flex align="center" justify="center" style={{ minHeight: '68vh' }}>
                    <Spin />
                </Flex>
            ) : instances.length > 0 ? (
                <Card styles={{ body: { padding: 0 } }}>
                    <Table
                        columns={columns}
                        dataSource={instances.map((template, index) => ({ key: index, ...template }))}
                        pagination={false}
                    />
                </Card>
            ) : (
                <Flex justify="center" align="center" style={{ minHeight: 'calc(-260px + 100vh)' }}>
                    <Empty description="Không có dữ liệu máy chủ" />
                </Flex>
            )}

            {Number(pages) > 1 && (
                <Flex justify="end" style={{ margin: '20px 0 10px 0' }}>
                    <Pagination
                        current={page || 1}
                        pageSize={20}
                        total={Number(pages) * 20}
                        onChange={(page) => {
                            setPage(page);
                            setSearchParams({ page });
                        }}
                    />
                </Flex>
            )}

            {modalVisible && (
                <Modal
                    centered
                    closable={false}
                    maskClosable={false}
                    open={modalVisible}
                    onOk={handleStopInstance}
                    onCancel={() => setModalVisible(false)}
                    width={400}
                    okText={<Fragment>{loadingAction ? <Spin size="small" /> : 'Xác nhận'}</Fragment>}
                    cancelText="Hủy"
                    okButtonProps={{ disabled: loadingAction }}
                    cancelButtonProps={{ disabled: loadingAction }}
                    title={action.charAt(0).toUpperCase() + action.slice(1)}
                >
                    <p>Bạn có muốn {action.charAt(0).toUpperCase() + action.slice(1)} máy chủ không?</p>
                </Modal>
            )}
        </Billing>
    );
}

export default Instances;
