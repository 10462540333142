import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
    IconCpu,
    IconCopy,
    IconPower,
    IconResize,
    IconNetwork,
    IconRefresh,
    IconActivity,
    IconSettings,
    IconArrowLeft,
    IconCalendarPlus,
    IconCurrencyDollar,
} from '@tabler/icons-react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Avatar, Breadcrumb, Button, Card, Col, Flex, Row, Spin, Switch, Tooltip, notification } from 'antd';

import router from '~/configs/routes';
import IconBalance from '~/assets/icon/IconBalance';
import { logoutUserSuccess } from '~/redux/reducer/auth';
import { requestUserGetInstanceDetail } from '~/services/billing';
import imageAvatarDefault from '~/assets/image/avatar-default.png';
import { calculateDaysLeft, convertCurrency, serviceCopyKeyBoard } from '~/configs';

function InstanceDetail() {
    const [loading, setLoading] = useState(true);
    const [instance, setInstance] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { instance_id } = useParams();
    const { pathname } = useLocation();

    useEffect(() => {
        if (instance_id) {
            document.title = `Thegioicode.vn - #${instance_id}`;

            const fetch = async () => {
                const result = await requestUserGetInstanceDetail(instance_id);

                setLoading(false);
                if (result.status === 401 || result.status === 403) {
                    dispatch(logoutUserSuccess());
                    navigate(`${router.login}?redirect_url=${pathname}`);
                } else if (result.status === 200) {
                    setInstance(result.data);
                } else {
                    navigate(router.billing_instances);
                    notification.error({
                        message: 'Thông báo',
                        description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
                    });
                }
            };
            fetch();
        } else {
            navigate(router.billing_instances);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instance_id]);

    return (
        <div className="container">
            <Row>
                <Col span={24}>
                    <Flex className="gap-2 mb-3 ml-2">
                        <Button size="small" className="box-center" onClick={() => navigate(router.billing_instances)}>
                            <IconArrowLeft size={18} />
                        </Button>
                        <Breadcrumb
                            className="flex-1"
                            items={[
                                {
                                    title: <Link to={router.home}>Trang chủ</Link>,
                                },
                                {
                                    title: <Link to={router.billing_instances}>Instances</Link>,
                                },
                                {
                                    title: `#${instance_id}`,
                                },
                            ]}
                        />
                    </Flex>
                </Col>

                {!loading && instance ? (
                    <Col span={24}>
                        <Row style={{ rowGap: 16 }}>
                            <Col span={24} style={{ padding: '0 8px' }}>
                                <Card styles={{ body: { adding: '18px 18px 0' } }}>
                                    <div className="d-flex justify-content-between gap-2">
                                        <Avatar
                                            src={instance.image.icon_url || imageAvatarDefault}
                                            style={{ width: 50, height: 50, lineHeight: 50, fontSize: 18 }}
                                            alt="Image"
                                        />
                                        <div className="flex-1 d-flex justify-content-between flex-wrap row-gap-2">
                                            <div>
                                                <h3 className="mb-0 font-size-18 font-bold">{instance.display_name.toUpperCase()}</h3>
                                                <div className="d-flex flex-wrap gap-4 row-gap-1 font-size-13 mt-2px">
                                                    <div className="d-flex text-subtitle text-normal gap-1">
                                                        <span className="anticon cursor-pointer text-primary">
                                                            <IconCalendarPlus size={14} />
                                                        </span>

                                                        {moment(instance.created_at).format('DD/MM/YYYY HH:mm:ss')}
                                                    </div>
                                                    <div
                                                        className="d-flex text-subtitle text-normal hover-bg-gray gap-1 cursor-pointer"
                                                        onClick={() => serviceCopyKeyBoard(instance.order_info.access_ipv4)}
                                                    >
                                                        <span className="anticon cursor-pointer text-primary">
                                                            <IconNetwork size={14} />
                                                        </span>

                                                        {instance.order_info.access_ipv4}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end flex-wrap gap-1-5">
                                                <Tooltip title="Tắt">
                                                    <Button className="box-center">
                                                        <IconPower width={20} height={20} />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Khởi động lại">
                                                    <Button className="box-center">
                                                        <IconRefresh width={20} height={20} />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Gia hạn">
                                                    <Button className="box-center">
                                                        <IconBalance width={20} height={20} />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Thay đổi cấu hình">
                                                    <Button className="box-center">
                                                        <IconResize width={20} height={20} />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Cài lại Image">
                                                    <Button className="box-center">
                                                        <IconSettings width={20} height={20} />
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Col>

                            <Col md={24} xs={24} style={{ padding: '0 8px' }}>
                                <Row style={{ margin: '0px -8px' }}>
                                    <Col style={{ padding: '0px 8px' }} md={8} xs={24}>
                                        <Card styles={{ body: { padding: '18px 18px 15px' } }}>
                                            <div className="d-flex align-items-center gap-4">
                                                <Avatar
                                                    shape="square"
                                                    className="box-center"
                                                    icon={<IconCpu size={25} />}
                                                    style={{ width: 40, height: 40, lineHeight: 40, fontSize: 20 }}
                                                />
                                                <div className="flex-1">
                                                    <div className="font-bold font-size-15 text-subtitle">Bandwidth Usage</div>
                                                    <div className="font-bold font-size-25 text-primary">
                                                        {instance.bandwidth_usage} B
                                                        <span className="font-normal text-subtitle font-size-14"> / Unlimited</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col style={{ padding: '0px 8px' }} md={8} xs={24}>
                                        <Card styles={{ body: { padding: '18px 18px 15px' } }}>
                                            <div className="d-flex align-items-center gap-4">
                                                <Avatar
                                                    shape="square"
                                                    className="box-center"
                                                    icon={<IconActivity size={25} />}
                                                    style={{ width: 40, height: 40, lineHeight: 40, fontSize: 20 }}
                                                />
                                                <div className="flex-1">
                                                    <div className="font-bold font-size-15 text-subtitle">CPU Usage</div>
                                                    <div className="font-bold font-size-25 text-primary">{instance.cpu_usage} %</div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col style={{ padding: '0px 8px' }} md={8} xs={24}>
                                        <Card styles={{ body: { padding: '18px 18px 15px' } }}>
                                            <div className="d-flex align-items-center gap-4">
                                                <Avatar
                                                    shape="square"
                                                    className="box-center"
                                                    icon={<IconCurrencyDollar size={25} />}
                                                    style={{ width: 40, height: 40, lineHeight: 40, fontSize: 20 }}
                                                />
                                                <div className="flex-1">
                                                    <div className="font-bold font-size-15 text-subtitle">Current Charges</div>
                                                    <div className="font-bold font-size-25 text-primary">
                                                        {convertCurrency(instance.override_price)}
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>

                            <Col md={24} xs={24} style={{ padding: '0 8px' }}>
                                <Card
                                    styles={{ header: { paddingInline: 18, paddingBlock: 5, minHeight: 52 }, body: { padding: 18 } }}
                                    title={<div className="font-size-18 font-bold mb-0 white-space-break">Thông tin cơ bản</div>}
                                >
                                    <Row style={{ margin: '0px -12px', rowGap: 18 }}>
                                        <Col md={8} xs={24} style={{ padding: '0px 12px' }}>
                                            <div className="d-flex flex-column gap-4">
                                                <div>
                                                    <div className="text-black">IPv4</div>
                                                    <div className="flex-1 font-bold break-all text-subtitle">
                                                        {instance.order_info.access_ipv4}
                                                        <span
                                                            className="anticon cursor-pointer text-subtitle hover-blue ml-1"
                                                            onClick={() => serviceCopyKeyBoard(instance.order_info.access_ipv4)}
                                                        >
                                                            <IconCopy size={14} stroke={2} />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="text-black">Username</div>
                                                    <div className="flex-1 font-bold break-all text-subtitle">
                                                        {instance.order_info.username}
                                                        <span
                                                            className="anticon cursor-pointer text-subtitle hover-blue ml-1"
                                                            onClick={() => serviceCopyKeyBoard(instance.order_info.username)}
                                                        >
                                                            <IconCopy size={14} stroke={2} />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="text-black">Password</div>
                                                    <div className="flex-1 font-bold break-all text-subtitle">
                                                        {/* {instance.order_info.password}
                                                        <span
                                                            className="anticon cursor-pointer text-subtitle hover-blue ml-1"
                                                            onClick={() => serviceCopyKeyBoard(instance.order_info.password)}
                                                        >
                                                            <IconCopy size={14} stroke={2} />
                                                        </span> */}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="text-black">Port</div>
                                                    <div className="flex-1 font-bold break-all text-subtitle">
                                                        {instance.order_info.port}
                                                        <span
                                                            className="anticon cursor-pointer text-subtitle hover-blue ml-1"
                                                            onClick={() => serviceCopyKeyBoard(instance.order_info.port)}
                                                        >
                                                            <IconCopy size={14} stroke={2} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col md={8} xs={24} style={{ padding: '0px 12px' }}>
                                            <div className="d-flex flex-column gap-4">
                                                <div>
                                                    <div className="text-black">Vị trí</div>
                                                    <div className="flex-1 font-bold break-all text-subtitle">{instance.region.name}</div>
                                                </div>
                                                <div>
                                                    <div className="text-black">Plan</div>
                                                    <div className="flex-1 font-bold break-all text-subtitle">Standard</div>
                                                </div>
                                                <div>
                                                    <div className="text-black">Cấu hình</div>
                                                    <div className="flex-1 font-bold break-all text-subtitle">
                                                        {instance.product.core} vCPU | {instance.product.memory / 1024} GB RAM |{' '}
                                                        {instance.product.disk} GB SSD
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="text-black">Hết hạn ngày</div>
                                                    <div className="flex-1 font-bold break-all text-subtitle">
                                                        {moment(instance.expired_at).format('DD/MM/YYYY HH:mm')}

                                                        <span className="ml-1">
                                                            (Còn{' '}
                                                            <b className={calculateDaysLeft(instance.expired_at) < 8 ? 'text-danger' : ''}>
                                                                {calculateDaysLeft(instance.expired_at)}
                                                            </b>{' '}
                                                            ngày)
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col md={8} xs={24} style={{ padding: '0px 12px' }}>
                                            <div className="d-flex flex-column gap-4">
                                                <div>
                                                    <div className="text-black">Trạng thái</div>
                                                    <div className="text-info font-bold text-uppercase">{instance.status}</div>
                                                </div>
                                                <div>
                                                    <div className="text-black">Image</div>
                                                    <div className="flex-1 font-bold break-all text-subtitle">{instance.image.name}</div>
                                                </div>
                                                <div>
                                                    <div className="text-black">Tổng phí</div>
                                                    <div className="flex-1 font-bold break-all text-subtitle">
                                                        {convertCurrency(instance.override_price)}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="text-black">Tự động gia hạn</div>
                                                    <div className="flex-1 font-bold break-all text-subtitle">
                                                        <Switch checkedChildren="Bật" unCheckedChildren="Tắt" value={instance.auto_renew} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                ) : (
                    <Flex align="center" justify="center" className="w-full" style={{ minHeight: '60vh' }}>
                        <Spin />
                    </Flex>
                )}
            </Row>
        </div>
    );
}

export default InstanceDetail;
